import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de cònsola, dur, pla o ondulat, sobretot al marge. Pot mesurar fins a 50 cm de diàmetre i 10 cm de gruixa. La part superior presenta zones concèntriques i és de color marró. El marge, en canvi, és blanc en estat fresc. Té tubs estratificats en capes anulars i porus petits i blancs al principi, tornant marró rogencs. Les espores són marró fosc en massa, el·líptiques, llises, hialines, amb doble paret, de 7-8 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      